import axios from 'axios'
import { Challenge } from '@bertazzoni/common/src/models/leaderboard.model'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/leaderboards'

class LeaderboardService {
  async createOne(newChallenge: Challenge): Promise<void> {
    return await axios.post(`${API_URL}/createOne`, newChallenge)
  }

  async getCurrentAdminLeaderboard(): Promise<Challenge> {
    const response = await axios.get(`${API_URL}/getLeaderboard/admin`)
    return response.data
  }

  async getCurrentUserLeaderboard(): Promise<Challenge> {
    const response = await axios.get(`${API_URL}/getLeaderboard/user`)
    return response.data
  }
}

export default new LeaderboardService()
