











































import { LeaderBoardScore } from '@bertazzoni/common/src/models/leaderboard.model'
import { Component, Vue, Prop } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
@Component({
  name: 'ChallengeList',
  components: { Icons }
})
export default class ChallengeList extends Vue {
  @Prop({ required: true }) currentScores!: LeaderBoardScore[]
  private selectedDealer = ''

  get isOngoingChallenge(): boolean {
    return this.currentScores !== null
  }
  get areParticipants(): boolean {
    return this.isOngoingChallenge && this.currentScores.length !== 0
  }

  get filteredList() {
    if (this.selectedDealer === '') return this.currentScores
    else
      return this.currentScores.filter((score: LeaderBoardScore) => {
        return score.dealerName.toLowerCase().indexOf(this.selectedDealer.toLowerCase()) !== -1
      })
  }
}
