
















import { Vue, Component } from 'vue-property-decorator'
import { Challenge, LeaderBoardScore } from '@bertazzoni/common/src/models/leaderboard.model'
import LeaderboardService from '@bertazzoni/common/src/services/LeaderboardService'
import CurrentChallenge from '@bertazzoni/back/views/LeaderBoard/components/CurrentChallenge.vue'
import ChallengeList from '@bertazzoni/back/views/LeaderBoard/components/ChallengeList.vue'
import Loader from '@bertazzoni/common/components/Loader.vue'

@Component({
  components: {
    CurrentChallenge,
    ChallengeList,
    Loader
  }
})
export default class LeaderBoard extends Vue {
  private onGoingChallenge: Challenge = null
  private currentScores: LeaderBoardScore[] = null
  private pageLoaded = false

  async created(): Promise<void> {
    const leaderboard = await LeaderboardService.getCurrentAdminLeaderboard()
    if (leaderboard) {
      this.onGoingChallenge = leaderboard
      this.currentScores = leaderboard.scores
    }
    this.pageLoaded = true
  }
  goResetPage(): void {
    this.$router.push('/leader-board/reset')
  }
}
