


































import { Challenge } from '@bertazzoni/common/src/models/leaderboard.model'
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'CurrentChallenge'
})
export default class CurrentChallenge extends Vue {
  @Prop({ required: true }) currentChallenge!: Challenge

  get isOngoingChallenge(): boolean {
    return !!this.currentChallenge
  }
}
